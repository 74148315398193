<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="gfGFdriedfruitsCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import GFapricot from "../assets/GOODFOOD/GFdf/apricotdry.jpg";
import GFapricotBio from "../assets/GOODFOOD/GFdf/apricotdrybio.jpg";
import GFgingerS from "../assets/GOODFOOD/GFdf/ginger.jpg";
import GFfig from "../assets/GOODFOOD/GFdf/figdry.jpg";
import GFapple from "../assets/GOODFOOD/GFdf/apple.jpg";
import GFgoji from "../assets/GOODFOOD/GFdf/godji.jpg";
import GFprunes from "../assets/GOODFOOD/GFdf/GF-prunewithout_200.jpg";
import GFcherry from "../assets/GOODFOOD/GFdf/GF-DP-cherry.jpg";
import GFmelon from "../assets/GOODFOOD/GFdf/GF-DP-melon.jpg";
import GFraisin from "../assets/GOODFOOD/GFdf/GF-DP-raisin.jpg";
import GFcranberry from "../assets/GOODFOOD/GFdf/GF-DP-crunberry.jpg";
import GFmango from "../assets/GOODFOOD/GFdf/GF-DP-mango.jpg";
import GFpapaya from "../assets/GOODFOOD/GFdf/GF-DP-papaya.jpg";
import GFdates from "../assets/GOODFOOD/GFdf/GF-DP-datewithout.jpg";
import GFdateswS from "../assets/GOODFOOD/GFdf/GF-DP-datewith.jpg";

export default {
  name: "GoodFoodDriedfruits",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Сухофрукты и сушёные ягоды",
      gfGFdriedfruitsCards: [
        {
          picture: GFapricot,
          text: "GOOD FOOD Абрикосы сушеные 200 г",
          id: 1,
          description:
            "Состав: абрикосы сушеные без косточек, консервант Е220.<br>Продукт может содержать косточки либо фрагменты косточек, следы арахиса, кунжута и орехов. Рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки 4,0 г, Жиры 1,0 г, Углеводы 48,0 г, Пищевые волокна 2,1 г<br>Энергетическая ценность 217 Ккал.<br>Хранить при температуре от +5 °С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GFapricotBio,
          text: "GOOD FOOD Абрикосы сушеные без обработки консервантом 200 г",
          id: 2,
          description:
            "Состав: абрикосы сушеные без косточки.<br>Продукт может содержать косточки либо фрагменты косточек, следы арахиса, кунжута и орехов. Рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки 4,0 г, Жиры 1,0 г, Углеводы 48,0 г, Пищевые волокна 2,1 г<br>Энергетическая ценность 217 Ккал.<br>Хранить при температуре +20°С±3°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: GFgingerS,
          text: "GOOD FOOD Имбирь в сахаре 130 г",
          id: 3,
          description:
            "Состав: Имбирь, сахар, консервант E220.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 0,0 г, Жиры 0,67 г, Углеводы 94,7 г<br>Энергетическая ценность 385 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70% Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFfig,
          text: "GOOD FOOD Инжир сушеный 200 г",
          id: 4,
          description:
            "Состав: инжир сушеный.<br>Продукт может содержать косточки либо фрагменты косточек, следы арахиса, кунжута и орехов.<br>Допускается засахаривание сухофрукта с образованием кристаллов сахара на поверхности.<br>Рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки 4,0 г, Жиры 1,0 г, Углеводы 50,0 г<br>Энергетическая ценность 225 Ккал.<br>Хранить при температуре +20°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFapple,
          text: "GOOD FOOD Яблоко сушеное 70 г",
          id: 5,
          description:
            "Состав: яблоки сушеные (яблоки, регулятор кислотности лимонная кислота, консервант E220)<br>Продукт может содержать косточки, фрагменты косточек, следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки 1,0 г, Жиры 1,6 г, Углеводы 68,7 г<br>Энергетическая ценность 256 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GFgoji,
          text: "GOOD FOOD Ягоды годжи 130 г",
          id: 6,
          description:
            "Состав: ягода годжи сушеная, консервант Е220.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 16,6 г, Жиры 3,1 г, Углеводы 60,4 г, Пищевые волокна 22,9 г<br>Энергетическая ценность 336 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70% Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFprunes,
          text: "GOOD FOOD Чернослив без косточки 200 г",
          id: 7,
          description:
            "Состав: сливы сушеные без косточки, консервант Е202, масло подсолнечное.<br>Продукт может содержать косточки либо фрагменты косточек, следы арахиса, кунжута и орехов. Рекомендуется мыть перед употреблением.<br>Пищевая ценность в 100 г: Белки 1,9 г, Жиры 0,5 г, Углеводы 59,5 г<br>Энергетическая ценность 256 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70% Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFcherry,
          text: "GOOD FOOD Вишня сушеная 130 г",
          id: 8,
          description:
            "Состав: вишня сушеная, сахар, регулятор кислотности кислота лимонная, консервант Е220, краситель кармины.<br>Продукт может содержать косточки, фрагменты косточек, следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 0,5 г, Жиры 0,0 г, Углеводы 73,0 г, Пищевые волокна 1,7 г<br>Энергетическая ценность 286 Ккал.<br>Хранить при температуре от + 17 0С до +23 0С относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: GFmelon,
          text: "GOOD FOOD Дыня тайская 110 г",
          id: 9,
          description:
            "Состав: дыня тайская, сахар, регулятор кислотности лимонная кислота, консервант Е220.<br>Продукт может содержать следы арахиса, кунжута, орехов.<br>Пищевая ценность в 100 г: Белки 1 г, Жиры 0,9 г, Углеводы 82 г, Пищевые волокна 3 г<br>Энергетическая ценность 341 Ккал.<br>Хранить при температуре +20°С±3°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: GFraisin,
          text: "GOOD FOOD изюм Джамбо 150 г",
          id: 10,
          description:
            "Состав: виноград без косточек сушеный, масло растительное, консервант Е220.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 2,5 г, Жиры 0,2 г, Углеводы 63,5 г, Пищевые волокна 9 г<br>Энергетическая ценность 321 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFcranberry,
          text: "GOOD FOOD Клюква сушеная 130 г",
          id: 11,
          description:
            "Состав: клюква, сахар, подсолнечное масло, регулятор кислотности – лимонная кислота.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 1,0г, Жиры 1,0 г, Углеводы 82 г, Пищевые волокна 24 г<br>Энергетическая ценность 341 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFmango,
          text: "GOOD FOOD Манго сушеное 110 г",
          id: 12,
          description:
            "Состав: манго, сахар, регулятор кислотности – кислота лимонная, консервант Е220.<br>Продукт может содержать следы арахиса, кунжута, орехов<br>Пищевая ценность в 100 г: Белки 4 г, Жиры 0 г, Углеводы 65 г, Пищевые волокна 2 г<br>Энергетическая ценность 280 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GFpapaya,
          text: "GOOD FOOD Папайя с ароматом тайской дыни 110г",
          id: 13,
          description:
            "Состав: папайя сушеная, сахар, регулятор кислотности – лимонная кислота, консервант Е220, ароматизатор «Канталуп».<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 0,9 г, Жиры 0,8 г, Углеводы 81,8 г, Пищевые волокна 2,4 г<br>Энергетическая ценность 337 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFdates,
          text: "GOOD FOOD Финики сушеные, без косточки 200 г",
          id: 14,
          description:
            "Состав: финики сушеные без косточки, глюкозный сироп, консервант Е202.<br>Продукт может содержать косточки либо фрагменты косточек, следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 2,0 г, Жиры 0,5 г, Углеводы 66,0 г.<br>Энергетическая ценность 276 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFdateswS,
          text: "GOOD FOOD Финики сушеные, с косточкой 200 г",
          id: 15,
          description:
            "Состав: финики сушеные без косточки, глюкозный сироп, консервант Е202.<br>Продукт может содержать косточки либо фрагменты косточек, следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 2,0 г, Жиры 0,5 г, Углеводы 66,0 г.<br>Энергетическая ценность 276 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
